body {
    font-family: "Fira Code", sans-serif;
    font-size: 12px;
    margin: 0;
    /*background: linear-gradient(to right, rgba(107, 0, 185, 0.57), rgb(241, 119, 255));*/
    background: linear-gradient(to right, rgb(75, 32, 160), rgb(241, 119, 255));
    /*background: mediumpurple;*/
    /*background: black;*/
    height: 100vh;
    width: 100vw;
}

.gradBG {
    background: linear-gradient(to right, rgb(75, 32, 160), rgb(241, 119, 255));
}

/* Used to prevent collision with footer */
.collision-container {
    margin-bottom: 10%;
}

.txt-aqua {
    color: aqua;
}

.std-shadow {
    box-shadow: 0 4px 8px 0 #201f1f;
}

.red-shadow {
    box-shadow: 0 4px 8px 0 #ff1212;
}

.blue-shadow {
    box-shadow: 0 4px 8px 0 #12ffd9;
}

.te-orange {
    color: #f24c1a;
}

.corith-gradient {
    background: linear-gradient(rgb(3, 8, 79), rgb(56,255,247));
    /*background: linear-gradient(rgb(10,79,3), rgb(56,255,247));*/
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@responsive {
    .heightClass {
        /*background: black;*/
        height: 100vh;
    }
}


@font-face {
    font-family: "Chalkduster";   /*Can be any text*/
    src: local("Chalkduster"),
    url("./fonts/Chalkduster.ttf") format("truetype");
}