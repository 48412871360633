.sub-form {
    border-radius: 30px;
    box-shadow: 0 4px 8px 0px #9258ab;
}

.input-btn {
    color: white;
}

.sub-form .lab {
    color: #d199f6;
    padding: 9px;
}

.sub-form .input {
    width: 100%;
    height: 25px;
}

.heightClass {
    height: 100vh;
}
