.lang-square {
    height: 200px;
    width: 250px;
    /*background: #5b21b6;*/
    border-radius: 15px;

}

body {
    /*background: #190b0b;*/
}

.backGroundLTC {
    background: #282828;
}