.welcome {
    color: white;
}

.welcome-pgraph {
    color: white;
    /*padding:  20px;*/
    /*box-shadow: 0 4px 8px 0 rgba(60,163,48,0.12) , 0 2px 4px 0 rgba(56,255,247,0.08);;*/
    /*box-shadow: 0 4px 8px 0px lawngreen;*/
    border-radius: 25px;
}


.welcome-pgraph a {
    text-decoration: none;
    color: #d98aff;
}

.gitem {
    color: white;
    padding: 10px;
}

.recentPrj {
    border-radius: 25px;
    box-shadow: 0 5px 10px 10px #af52ff;
}

.hire-me {
    border-radius: 15px;
    box-shadow: 0 4px 8px 0px #15ff00;
}

.temp {
    background: aqua;
    color: maroon;
}

.homeBg {
    background: #282828;
    /*background: linear-gradient(to right, rgba(107, 0, 185, 0.57), rgb(241, 119, 255));*/
;
}