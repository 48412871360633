nav {
    /*background: linear-gradient(rgba(172, 62, 255, 0.57), rgb(255, 165, 56));*/
    /*background: linear-gradient(rgba(72, 72, 72, 0.57), rgb(191, 74, 255));*/
    /*background: linear-gradient(rgba(72, 72, 72, 0.57), rgb(150, 33, 208));*/
    /*background: linear-gradient(to right, rgb(241, 119, 255), rgb(75, 32, 160) );*/
    background: #1a1919;
    /*background: linear-gradient(rgb(76, 79, 249), rgb(56,255,247));*/
}

.nav-item a {
    padding: 1em 1em;
    display: block;
}

.active {
    /*background-color: #00a6ff;*/
    background-color: #2d1f45;
    /*background-color: #7e22ce;*/
    /*background-color: #c084fc;*/
    color: white;
    /*font-size: 20px;*/
}

.sideBarShadow {
    /*box-shadow: 0 4px 8px 0px #2e2e2e;*/

}