
.fiveList {
    /*width: 750px;*/
    /*height: 75px;*/
}

.classHeight {
    height: 100vh;
    /*height: available;*/
}

.cotton-candy {
    background: linear-gradient(to right, rgb(0, 51, 255), rgb(241, 119, 255));
}