.footer {
    /*background: linear-gradient(to left , #34050e, pink);*/
    /*background: linear-gradient(rgb(60,163,48), rgb(56,255,247));*/
    position: center;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
}

.footer p {
    font-size: medium;
    color: rgba(183, 183, 183, 0.66);
    text-align: center;
}
