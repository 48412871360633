.projLink-bg {
    background: rgba(0.8,0.2, 0.6, 0.3);
    font-size: xx-small;
    color: rgb(255, 255, 255);
}

/* Left */
.shadow1 {
    box-shadow: 0 4px 8px 0 #a64df4;
}
.shadow2 {
    box-shadow: 0 2px 4px 0 #e280ff;
}
.card-left-bg {
    background: linear-gradient(rgb(37, 0, 58), rgb(176, 31, 222));
}

.card-left-bg1 {
    background: linear-gradient(rgb(200, 33, 255), rgba(0, 183, 168, 0.93) 80%);
}

.card-left-bg2 {
    background: linear-gradient(rgba(190, 0, 211, 0.99) 30% , rgb(188, 107, 40));
}
